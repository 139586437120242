import React, { useState, useContext } from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { Link, navigate } from "gatsby";
import { Fade as Hamburger } from "hamburger-react";
import { useSpring } from "@react-spring/core";
import "../styles/hamburger.css";
import scrollTo from "gatsby-plugin-smoothscroll";
import SNAPLogo from "../assets/logo/SNAP_logo.svg";
import { heights, colors, fonts, widths } from "../styles/variables";
import { getEmSize } from "../styles/mixins";
import { animated } from "@react-spring/web";
import LineIcon from "../assets/pages/Contact/lineblack.svg";
import IgIcon from "../assets/pages/Contact/igblack.svg";
import { HeaderContext } from "../pages/aboutandcontact";

const StyledHeader = styled.div<any>`
  height: ${heights.header}px;
  background-color: transparent;
  color: ${transparentize(0.5, colors.white)};
  position: sticky;
  top: 0px;
  z-index: 99;
  @media only screen and (max-width: 576px) {
    height: 50px;
  }
`;

const HeaderInner = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 0px;
  justify-content: space-between;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${getEmSize(widths.xl)}em;
  @media only screen and (max-width: 1200px) {
    max-width: ${getEmSize(widths.md)}em;
    margin: 0px 60px;
  }
  @media only screen and (max-width: 768px) {
    max-width: ${getEmSize(widths.sm)}em;
    margin: 0px 30px;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const MobileHeaderInner = styled<any>(animated.div)`
  display: none;

  @media only screen and (max-width: 576px) {
    display: flex;
    align-items: center;
    margin: 0px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: ${getEmSize(widths.lg)}em;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    padding-top: 164px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100vh;
  }
`;

const HomepageLink = styled(Link)<any>`
  color: ${colors.black};
  cursor: pointer;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  font-family: ${fonts.sansSerif};
  white-space: nowrap;
  margin-right: 60px;
  border-bottom: ${props => (props.active ? "3px solid #EF602B" : "0px")};
  text-transform: uppercase;
  :hover {
    opacity: 0.9;
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin: 0px 0px 26px 0px;
    border-bottom: 0px;
  }
`;

const HomepageLinkScroll = styled.p<any>`
  color: ${colors.black};
  cursor: pointer;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  font-family: ${fonts.sansSerif};
  white-space: nowrap;
  margin-right: 60px;
  border-bottom: ${props => (props.active ? "3px solid #EF602B" : "0px")};
  text-transform: uppercase;
  margin-bottom: 0px;
  :hover {
    opacity: 0.9;
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin: 0px 0px 26px 0px;
    border-bottom: 0px;
  }
`;

const Logo = styled.img`
  position: relative;
  width: 195px;
  height: 41px;
  margin-right: 28px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 175px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const MobileLogo = styled.img`
  display: none;
  @media only screen and (max-width: 576px) {
    position: relative;
    position: fixed;
    display: block;
    width: 84px;
    height: 19px;
    top: 16px;
    left: 16px;
    z-index: 9999;
  }
`;

const HamburgerContainer = styled.div`
  display: none;
  @media only screen and (max-width: 576px) {
    position: fixed;
    top: 0px;
    right: 0px;
    display: block;
    width: 48px;
    height: 48px;
    z-index: 999;
  }
`;

const SocialIcon = styled.img`
  width: 56px;
  height: 56px;
  margin-top: 28px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const BottomInfo = styled.div`
  position: absolute;
  width: 100%;
  height: 164px;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 0px;
  left: 0px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: black;
  margin-bottom: 24px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AllRightReserved = styled.p`
  font-style: normal;
  font-weight: 900;
  color: #000000;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }
`;

const DesignedBy = styled.p`
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (max-width: 576px) {
    font-size: 10px;
    text-align: center;
  }
`;

interface HeaderProps {
  title?: string;
  urlParam?: any;
}

const Header: React.FC<HeaderProps> = ({ urlParam }: any) => {
  const tabName = useContext(HeaderContext);
  const [isOpen, setIsOpen] = useState(false);
  const springStyles = useSpring({
    transform: isOpen ? "translateY(0px)" : "translateY(-100vh)",
    backgroundColor: isOpen ? "#f9d454" : "transparent",
    config: { duration: 200 }
  });

  const handleNavigate = (url: string) => {
    if (window) {
      if (window.location.pathname === "/") {
        if (url === "#contact") {
          navigate("/aboutandcontact#contact");
        } else {
          navigate("/aboutandcontact");
        }
      }
    }
    setIsOpen(false);
    scrollTo(url);
  };

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }

  return (
    <>
      <MobileLogo src={SNAPLogo} onClick={() => navigate("/")} />
      <StyledHeader id="#top">
        {/* For Desktop */}
        <HeaderInner isOpen={isOpen}>
          <Logo src={SNAPLogo} onClick={() => navigate("/")} />
          <HomepageLink
            to="/"
            active={urlParam && urlParam.pathname === "/"}
            isOpen={isOpen}
          >
            Customize Your Booth
          </HomepageLink>
          <HomepageLinkScroll
            active={tabName === "about"}
            isOpen={isOpen}
            onClick={() => handleNavigate("#top")}
          >
            About Snap Services
          </HomepageLinkScroll>
          <HomepageLinkScroll
            active={tabName === "contact"}
            isOpen={isOpen}
            onClick={() => handleNavigate("#contact")}
          >
            Contact Us
          </HomepageLinkScroll>
        </HeaderInner>
        {/* ---------------------------- */}
        {/* For Mobile */}
        <MobileHeaderInner isOpen={isOpen} style={springStyles}>
          <Logo src={SNAPLogo} onClick={() => navigate("/")} />
          <HomepageLink
            to="/"
            active={urlParam && urlParam.pathname === "/"}
            isOpen={isOpen}
            onClick={() => handleNavigate("/")}
          >
            Customize Your Booth
          </HomepageLink>
          <HomepageLinkScroll
            active={tabName === "about"}
            isOpen={isOpen}
            onClick={() => handleNavigate("#top")}
          >
            About Snap Services
          </HomepageLinkScroll>
          <HomepageLinkScroll
            active={tabName === "contact"}
            isOpen={isOpen}
            onClick={() => handleNavigate("#contact")}
          >
            Contact Us
          </HomepageLinkScroll>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              flexDirection: "column"
            }}
          >
            <SocialIcon
              src={LineIcon}
              onClick={() => navigate("http://line.me/ti/p/~@snapservice")}
            />
            <SocialIcon
              src={IgIcon}
              onClick={() =>
                navigate("https://www.instagram.com/snapservice__/")
              }
            />
          </div>
          <BottomInfo>
            <Line />
            <Row>
              <AllRightReserved>
                © SCULPTURE BANGKOK 2021. ALL RIGHTS RESERVED.
              </AllRightReserved>
            </Row>
            <Row>
              <DesignedBy>
                SITE DESIGNED BY PLOY PHAIRAM D. | DEVELOPMENT BY MIX CHANAWEE
              </DesignedBy>
            </Row>
          </BottomInfo>
        </MobileHeaderInner>
        {/* ---------------------------- */}
      </StyledHeader>
      <HamburgerContainer>
        <Hamburger
          size={20}
          toggled={isOpen}
          onToggle={() => setIsOpen(!isOpen)}
          distance="lg"
          rounded
        />
      </HamburgerContainer>
    </>
  );
};

export default Header;
