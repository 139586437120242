import React from "react";
import styled from "styled-components";
import { SnapService } from "./SnapService";

import BalloonSticker from "../../../assets/pages/Contact/About/balloon_sticker.png";
import EyeSticker from "../../../assets/pages/Contact/About/eye_sticker.png";
import FlowerSticker from "../../../assets/pages/Contact/About/flower_sticker.png";
import SnapRedSticker from "../../../assets/pages/Contact/About/snap_red_sticker.png";
import SnapSticker from "../../../assets/pages/Contact/About/snap_sticker.png";
import StampSticker from "../../../assets/pages/Contact/About/stamp_sticker.png";
import StarSticker from "../../../assets/pages/Contact/About/star_sticker.png";
import StarYellowSticker from "../../../assets/pages/Contact/About/star_yellow_sticker.png";
import ToiletSticker from "../../../assets/pages/Contact/About/toilet_sticker.png";
import { SnapImage } from "./SnapImage";
import { SnapImageMobile } from "./SnapImageMobile";

const Container = styled.div`
  height: fit-content;
  position: relative;
`;

const Sticky = styled.div`
  height: fit-content;
`;

const Content = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  flex-direction: column;
  padding-bottom: 200px;
  @media only screen and (max-width: 576px) {
    padding-top: 80px;
    padding-bottom: 0px;
  }
`;

const StickerImage = styled.img<any>`
  position: absolute;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  @media only screen and (max-width: 768px) {
    top: ${props => props.top * 0.9}%;
    left: ${props => props.left * 0.9}%;
    width: ${props => props.width * 0.8}px;
    height: ${props => props.height * 0.8}px;
  }
  @media only screen and (max-width: 576px) {
    top: ${props => props.topMobile}%;
    left: ${props => props.leftMobile * 0.9}%;
    width: ${props => props.width * 0.45}px;
    height: ${props => props.height * 0.45}px;
  }
`;

const FixedStickerImage = styled.img<any>`
  position: fixed;
  z-index: 0;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  @media only screen and (max-width: 768px) {
    top: ${props => props.top * 0.9}%;
    left: ${props => props.left * 0.9}%;
    width: ${props => props.width * 0.8}px;
    height: ${props => props.height * 0.8}px;
  }
  @media only screen and (max-width: 576px) {
    top: ${props => props.topMobile}%;
    left: ${props => props.leftMobile * 0.9}%;
    width: ${props => props.width * 0.45}px;
    height: ${props => props.height * 0.45}px;
  }
`;

const StickerContainer = styled.div<any>`
  transform: ${props => `translate3d(0px,-${props.stickerY}px,0px)`};
  -webkit-transform: ${props => `translate3d(0px,-${props.stickerY}px,0px)`};
  transition: all linear;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  @media only screen and (max-width: 576px) {
    transform: none;
    -webkit-transform: none;
  }
`;

const FixedSticker = [
  {
    key: "toilet",
    icon: ToiletSticker,
    left: 15,
    top: 68,
    leftMobile: 15,
    topMobile: 70,
    width: 250,
    height: 250
  },
  {
    key: "flower",
    icon: FlowerSticker,
    left: 70,
    top: 66,
    leftMobile: 70,
    topMobile: 67,
    width: 250,
    height: 250
  }
];

const Sticker = [
  {
    key: "balloon",
    icon: BalloonSticker,
    left: 80,
    top: 6,
    leftMobile: 80,
    topMobile: 11,
    width: 200,
    height: 200
  },
  {
    key: "eye",
    icon: EyeSticker,
    left: 13,
    top: 34,
    leftMobile: 6,
    topMobile: 34,
    width: 170,
    height: 170
  },
  {
    key: "snapred",
    icon: SnapRedSticker,
    left: 80,
    top: 65,
    leftMobile: 80,
    topMobile: 70,
    width: 200,
    height: 200
  },
  {
    key: "snap",
    icon: SnapSticker,
    left: 40,
    top: -4,
    leftMobile: 36,
    topMobile: -8,
    width: 280,
    height: 280
  },
  {
    key: "stamp",
    icon: StampSticker,
    left: 5,
    top: 9,
    leftMobile: 14,
    topMobile: 3,
    width: 150,
    height: 150
  },
  {
    key: "star",
    icon: StarSticker,
    left: 5,
    top: 60,
    leftMobile: 3,
    topMobile: 60,
    width: 200,
    height: 200
  },
  {
    key: "staryellow",
    icon: StarYellowSticker,
    left: 77,
    top: 40,
    leftMobile: 77,
    topMobile: 47,
    width: 150,
    height: 150
  }
];

export const Section1 = React.forwardRef((props: any, ref: any) => {
  return (
    <>
      <Container ref={ref}>
        <Sticky>
          {FixedSticker.map((fixedStickerData: any) => (
            <FixedStickerImage
              src={fixedStickerData.icon}
              left={fixedStickerData.left}
              top={fixedStickerData.top}
              leftMobile={fixedStickerData.leftMobile}
              topMobile={fixedStickerData.topMobile}
              width={fixedStickerData.width}
              height={fixedStickerData.height}
              key={fixedStickerData.key}
            />
          ))}
          <Content>
            <SnapService />
            <StickerContainer stickerY={props.stickerPosition}>
              {Sticker.map((stickerData: any) => (
                <StickerImage
                  src={stickerData.icon}
                  left={stickerData.left}
                  top={stickerData.top}
                  leftMobile={stickerData.leftMobile}
                  topMobile={stickerData.topMobile}
                  width={stickerData.width}
                  height={stickerData.height}
                  key={stickerData.key}
                />
              ))}
            </StickerContainer>

            <SnapImage />
          </Content>
        </Sticky>
      </Container>
    </>
  );
});
