import React, { useRef, useState, useEffect, createContext } from "react";

import Page from "../components/Page";
import { ContactContainer } from "../components/Container";
import IndexLayout from "../layouts";
import { BackgroundImage } from "../components/pages/Index/Background";
import { Section1 } from "../components/pages/AboutAndContact/Section1";
import { Section2 } from "../components/pages/AboutAndContact/Section2";
import Helmet from "react-helmet";

export const HeaderContext = createContext("");

const AboutAndContactPage = (props: any) => {
  const [snapPosition, setSnapPosition] = useState(0);
  const [stickerPosition, setStickerPosition] = useState(0);
  const [tabName, setTabName] = useState<any>("about");
  const [multiplyFactor, setMultiplyFactor] = useState(0.5);
  const [multiplyFactorSnap, setMultiplyFactorSnap] = useState(0.7);
  const containerRef = useRef();
  const section1Ref = useRef();
  const section2Ref = useRef();

  const handleScroll = (e: any) => {
    let element = e.target;
    let scrollTop = element.scrollTop;
    let section1Start = section1Ref?.current?.clientHeight;
    let limit = section1Start - 620;
    if (scrollTop <= limit) {
      if (tabName !== "about") {
        setTabName("about");
      }
      setStickerPosition(scrollTop * multiplyFactor);
      // setSnapPosition(0);
    } else if (scrollTop >= limit) {
      // let position = limit - scrollTop;
      // setSnapPosition(position);
      if (tabName !== "contact") {
        setTabName("contact");
      }
    }
  };

  useEffect(() => {
    if (window) {
      if (window.innerWidth > 576) {
        setMultiplyFactor(0.7);
        setMultiplyFactorSnap(1);
      }
    }
  });

  return (
    <HeaderContext.Provider value={tabName}>
      <IndexLayout urlParam={props.location}>
        <Helmet>
          <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
        </Helmet>
        <Page>
          <ContactContainer onScroll={handleScroll} ref={containerRef}>
            <div id="top" />
            <Section1
              ref={section1Ref}
              stickerPosition={stickerPosition}
              snapPosition={snapPosition}
            />
            <Section2 ref={section2Ref} snapPosition={snapPosition} />
            <div id="contact" />
          </ContactContainer>
        </Page>
        <BackgroundImage page={props.location} />
      </IndexLayout>
    </HeaderContext.Provider>
  );
};

export default AboutAndContactPage;
