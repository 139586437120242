import React from "react";
import styled from "styled-components";
import { SnapImage } from "./SnapImage";
import Logo from "../../../assets/logo/SNAP_logo.svg";
import SnapBottom from "../../../assets/pages/Contact/snap_bottom.png";
import LineIcon from "../../../assets/pages/Contact/line.svg";
import IgIcon from "../../../assets/pages/Contact/ig.svg";
import { navigate } from "gatsby-link";

const Container = styled.div`
  height: 1400px;
  position: relative;
  @media only screen and (max-width: 768px) {
    height: 1100px;
  }
  @media only screen and (max-width: 576px) {
    height: 1200px;
  }
`;

const Sticky = styled.div`
  height: calc(100vh - 209px);
  position: sticky;
  top: 80vh;
  @media only screen and (max-width: 768px) {
    height: fit-content;
  }
  @media only screen and (max-width: 576px) {
    top: 90vh;
  }
`;

const Content = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FooterBlock = styled.div`
  width: 100%;
  height: 642px;
  display: flex;
  flex-direction: column;
  padding: 100px 145px;
  background-color: #6b9dd7;
  @media only screen and (max-width: 768px) {
    height: 500px;
    padding: 50px 100px;
  }
  @media only screen and (max-width: 576px) {
    height: calc(100vh - 50px);
    padding: 100px 10px;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SnapLogo = styled.img<any>`
  width: 195px;
  height: 45px;
  cursor: pointer;
  @media only screen and (max-width: 576px) {
    width: 105px;
    height: 24px;
    margin-bottom: 40px;
  }
`;

const LeftColumn = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;

const SocialBox = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const EmailHeader = styled.h4`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  display: flex;
  align-items: left;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const ContactHeader = styled.h4`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  display: flex;
  align-items: left;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const BottomImage = styled.img`
  width: 226px;
  height: 226px;
  margin-top: 48px;
  margin-bottom: 48px;
  @media only screen and (max-width: 768px) {
    margin: 10px;
    width: 150px;
    height: 150px;
  }
  @media only screen and (max-width: 576px) {
    margin: 10px;
  }
`;

const SocialIcon = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 32px;
  margin-top: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 48px;
    height: 48px;
    margin: 0px 20px 20px 20px;
  }
`;

const AllRightReserved = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #ffffff;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

const DesignedBy = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media only screen and (max-width: 576px) {
    font-size: 10px;
  }
`;

export const Section2 = React.forwardRef((props: any, section2Ref: any) => {
  const handleEmail = () => {
    if (window) {
      window.location.href =
        "mailto:snapphotoservice@gmail.com?Subject=Photobooth%20request";
    }
  };
  return (
    <>
      <Container ref={section2Ref}>
        <Sticky>
          <Content>
            {/* <SnapImage yPosition={props.snapPosition} /> */}
            <FooterBlock>
              <Row>
                <SnapLogo src={Logo} onClick={() => navigate("/")} />
              </Row>
              <Row>
                <LeftColumn>
                  <EmailHeader onClick={() => handleEmail()}>
                    SNAPPHOTOSERVICE@GMAIL.COM
                  </EmailHeader>
                  <SocialBox>
                    <SocialIcon
                      src={LineIcon}
                      onClick={() =>
                        navigate("http://line.me/ti/p/~@snapservice")
                      }
                    />
                    <SocialIcon
                      src={IgIcon}
                      onClick={() =>
                        navigate("https://www.instagram.com/snapservice__/")
                      }
                    />
                  </SocialBox>
                </LeftColumn>
                <Column>
                  <BottomImage src={SnapBottom} />
                </Column>
                <RightColumn>
                  <ContactHeader onClick={() => navigate("/")}>
                    CUSTOMIZE
                  </ContactHeader>
                  <ContactHeader onClick={() => navigate("/aboutandcontact")}>
                    ABOUT
                  </ContactHeader>
                </RightColumn>
              </Row>
              <Row>
                <AllRightReserved>
                  © SCULPTURE BANGKOK 2021. ALL RIGHTS RESERVED.
                </AllRightReserved>
              </Row>
              <Row>
                <DesignedBy>
                  SITE DESIGNED BY PLOY PHAIRAM D. | DEVELOPMENT BY MIX CHANAWEE
                </DesignedBy>
              </Row>
            </FooterBlock>
          </Content>
        </Sticky>
      </Container>
    </>
  );
});
