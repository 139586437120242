import React from "react";
import styled from "styled-components";
import WhiteBackgroundImage from "../../../assets/common/white_background.svg";
import YellowBackgroundImage from "../../../assets/common/yellow_background.svg";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  z-index: -1;
`;

const Stretch = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const BackgroundImage = ({ page }: { page: any }) => {
  const getBackgroundImage = (name: string) => {
    if (name === "/") {
      return WhiteBackgroundImage;
    } else if (name.includes("aboutandcontact")) {
      return YellowBackgroundImage;
    }
  };
  return (
    <Background>
      <Stretch
        src={getBackgroundImage(page && page.pathname)}
        alt="background_image"
      />
    </Background>
  );
};
