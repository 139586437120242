import React from "react";
import styled from "styled-components";
import SNAPLogo from "../../../assets/logo/SNAP_logo.svg";

const Container = styled.div`
  width: 911px;
  height: 847px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 67px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    height: fit-content;
    width: 80%;
  }
  @media only screen and (max-width: 576px) {
    padding: 40px 0px;
  }
`;

const SnapHeader = styled.h3`
  margin: 0px;
  font-size: 62px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ef602b;
  font-family: "Futura Condensed Extra Bold Italic";
  @media only screen and (max-width: 576px) {
    font-size: 32px;
  }
`;

const SnapSubHeader = styled.h4`
  font-family: Rois;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 32px;
  @media only screen and (max-width: 576px) {
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 20px;
  }
`;

const Desc1 = styled.p`
  font-family: "Kanit";
  font-weight: 600;
  font-size: 40px;
  color: #ef602b;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const Desc2 = styled.p`
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 80px;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: 155px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const BottomBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Line = styled.div`
  width: 6px;
  height: 130px;
  background-color: #ef602b;
  border-radius: 3px;
  margin-top: 3px;
  @media only screen and (max-width: 768px) {
    height: 3px;
    width: 120px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    height: 3px;
    margin-top: 0px;
    margin-bottom: 16px;
  }
`;

const BottomBlockHeader = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 3px;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const BottomBlockBody = styled.p`
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #000000;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const LetsTalk = styled.div`
  width: 330px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #ef602b;
  border: 1px solid #000000;
  box-sizing: border-box;
  filter: drop-shadow(3px 4px 99px #eba287);
  cursor: pointer;
  :hover {
    filter: drop-shadow(3px 4px 99px #ff6229);
  }
  @media only screen and (max-width: 576px) {
    width: 188px;
    height: 40px;
  }
`;

const LetsTalkText = styled.p`
  font-family: Rois;
  font-size: 32px;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  margin: 0px 0px -5px 0px;
  user-select: none;
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const SNAPLogoImg = styled.img`
  height: 45px;
  margin-bottom: 27px;
  @media only screen and (max-width: 576px) {
    height: 26px;
    margin-bottom: 26px;
  }
`;

const Br = styled.br`
  display: none;
  @media only screen and (max-width: 576px) {
    display: block;
  }
`;

export const SnapService = () => {
  const handleEmail = () => {
    if (window) {
      window.location.href =
        "mailto:snapphotoservice@gmail.com?Subject=Photobooth%20request";
    }
  };
  return (
    <>
      <Container>
        {/* <SnapHeader>SNAP SERVICE</SnapHeader> */}
        <SNAPLogoImg src={SNAPLogo} />
        <SnapSubHeader>
          Your Customizable <Br />
          Photobooth
        </SnapSubHeader>
        <Desc1>บริการตู้ถ่ายรูปแฟรนไชส์</Desc1>
        <Desc2>
          เกิดมาเพื่อตอบสนองความต้องการของ
          <br />
          ทุกคนที่ต้องการมีตู้ถ่ายรูปของตัวเอง
        </Desc2>
        <Bottom>
          <BottomBlock>
            <BottomBlockHeader>
              YOUR OWN <br />
              BOOTH COLOR
            </BottomBlockHeader>
            <BottomBlockBody>
              เลือกสีตู้ถ่ายรูป <br />
              ให้โดดเด่น
            </BottomBlockBody>
          </BottomBlock>
          <Line />
          <BottomBlock>
            <BottomBlockHeader>
              YOUR OWN <br />
              TEMPLATE
            </BottomBlockHeader>
            <BottomBlockBody>
              สร้างเทมเพลต <br />
              แบบฉบับของคุณเอง
            </BottomBlockBody>
          </BottomBlock>
          <Line />
          <BottomBlock>
            <BottomBlockHeader>
              YOUR OWN <br />
              STICKERS
            </BottomBlockHeader>
            <BottomBlockBody>
              ตกแต่งด้วย
              <br />
              สติกเกอร์ที่ไม่ซ้ำใคร
            </BottomBlockBody>
          </BottomBlock>
        </Bottom>
        <LetsTalk>
          <LetsTalkText onClick={() => handleEmail()}>LET'S TALK</LetsTalkText>
        </LetsTalk>
      </Container>
    </>
  );
};
