import * as React from "react";
import styled from "@emotion/styled";

import { widths } from "../styles/variables";
import { getEmSize } from "../styles/mixins";

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${getEmSize(widths.lg)}em;
`;

const ContactStyledContainer = styled.div<any>`
  position: relative;
  width: 100vw;
  overflow-y: scroll;
  height: calc(100vh - 109px);
  @media only screen and (max-width: 576px) {
    height: calc(100vh - 50px);
  }
`;

interface ContainerProps {
  className?: string;
  onScroll?: any;
}

const Container: React.FC<ContainerProps> = ({ children, className }: any) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;

export const ContactContainer = React.forwardRef((props: any, ref: any) => (
  <ContactStyledContainer
    className={props.className}
    onScroll={props.onScroll}
    ref={ref}
  >
    {props.children}
  </ContactStyledContainer>
));
