import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SnapMain from "../../../assets/pages/Contact/snap_main.png";
import SnapMainMobile from "../../../assets/pages/Contact/snap_main_mobile.png";

const SnapMainImage = styled.img<any>`
  width: 653px;
  height: 765px;
  position: absolute;
  z-index: 0;
  bottom: -600px;
  /* transition: all linear; */
  /* transform: ${props => `translate3d(0px,${props.yPosition}px,0px)`}; */
  @media only screen and (max-width: 576px) {
    display: block;
    width: 269px;
    height: 313px;
    bottom: -360px;
    z-index: 0;
    /* transform: ${props => `translate3d(0px,${props.yPosition * 0.7}px,0px)`};
    -webkit-transform: ${props =>
      `translate3d(0px,${props.yPosition * 0.7}px,0px)`}; */
  }
`;

export const SnapImage = ({ yPosition }: any) => {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    if (window) {
      if (window.innerWidth > 576) {
        setIsMobile(false);
      }
    }
  });
  return (
    <>
      <SnapMainImage
        src={isMobile ? SnapMainMobile : SnapMain}
        yPosition={yPosition}
      />
    </>
  );
};
